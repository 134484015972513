import profilePicture from './static/profile-picture.jpg';
import frndsLogo from './static/Frnds.png';
import bulsaiLogo from './static/Bulsai.png';
import GPTOPLogo from './static/GPTOP.png';
import floaterLogo from './static/Floater.png';
import carriageLogo from './static/Carriage.png';
import basinLogo from './static/Basin.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="MainContent">
        <div className="HStack">
          <img className="ProfilePicture" src={profilePicture} alt="Profile" />
          <h2>Nick Crews</h2>
        </div>
        <h3>Blog Posts</h3>
        <ul>
          <li>
          <p><a className="Link" href="https://medium.com/audible-tech-blog/metrics-revamped-774a53f5c112">Metrics Revamped - Audible Tech Blog</a></p>
          </li>
        </ul>

        <h3>iOS Applications</h3>
        <ul>
          <li>
            <p><a className="Link" href="https://frnds.app/">Frnds</a></p>
            <img className='AppLogo' src={frndsLogo} alt='Frnds' />
          </li>
          <li>
            <p>Carriage</p>
            <img className='AppLogo' src={carriageLogo} alt='Carriage' />
          </li>
          <li>
            <p>Floater</p>
            <img className='AppLogo' src={floaterLogo} alt='Floater' />
          </li>
        </ul>

        <h3>Other Projects</h3>
        <ul>
          <li>
            <p><a className="Link" href="https://github.com/nuckcrews/gpt-engineer">GPT Engineer</a></p>
            <img className='AppLogo' src={GPTOPLogo} alt='GPT Engineer' />
          </li>
          <li>
            <p><a className="Link" href="https://github.com/Bulsai">Bulsai</a></p>
            <img className='AppLogo' src={bulsaiLogo} alt='Bulsai' />
          </li>
          <li>
            <p><a className="Link" href="https://github.com/open-basin">Open Basin</a></p>
            <img className='AppLogo' src={basinLogo} alt='Open Basin' />
          </li>
        </ul>
      </div>
    </div>
  );
}

export default App;
